import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Modal
import Modal from "@mui/material/Modal";

//Icons
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

//Form
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import AddBankAccount from "../../components/addBankAccount/addBankAccount.component";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  WithdrawalAccountType,
} from "../../constants/DBConstants.contant";
import AddUPIDetails from "../../components/addUPIDetails/addUPIDetails.component";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import WithdrawalService from "../../services/withdrawal/withdrawal.service";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { ToastContext } from "../../context/toast.context";
import { IWithdrawalModel } from "../../interface/BusinessModels/IWithdrawalModel";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
//Import Css
import "../myBankCards/myBankCards.screen.style.css";
import { LoadingContext } from "../../context/loading.context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertDialog from "../../components/alertDialog/alertDialog.component";

type SavedAccountPageModel = {
  upi: IWithdrawalModel[];
  bankAccount: IWithdrawalModel[];
};

const MyBankCards: FunctionComponent<PageProps> = ({ title }) => {
  const navigate = useNavigate();
  const { withdrawalAccountType } = useSelector(
    (state: any) => state.lookupReducer
  );
  const [withdrawalAccount, setWithdrawalAccount] = useState<ILookupModel>({
    id: 0,
    value: "",
    type: "",
  });
  const _withdrawalService = new WithdrawalService({});
  const { setToastConfig } = useContext(ToastContext);
  const [savedAccounts, setSavedAccounts] = useState<SavedAccountPageModel>();
  const [activeEdit, setActiveEdit] = useState<IWithdrawalModel | null>();
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const [activeDeleteId, setActiveDeleteId] = useState<number>(0);
  const [isPrimary, setIsPrimary] = useState<any>({ id: 0, primary: false });
  const [openAddCard, setOpenAddCard] = React.useState(false);
  const handleOpenAddCard = () => {
    setOpenAddCard(true);
    setActiveEdit(null);
  };
  const { setLoading } = useContext(LoadingContext);
  useEffect(() => {
    document.title = title;
  }, []);

  const handleCloseAddCard = (response: IApiResponse | null) => {
    setOpenAddCard(false);
    setActiveEdit(null);
    if (response != null) {
      if (!response.isSuccess) {
        setToastConfig(ToastSeverity.Error, response.message, true);
      } else {
        loadSavedCards();
        setAlertConfig({
          description: response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
          },
        });
      }
    }
  };

  const RenderEvent = (props: any) => {
    switch (props.type.id) {
      case WithdrawalAccountType.BankAccount:
        return (
          <AddBankAccount editData={activeEdit} callBack={handleCloseAddCard} />
        );
      case WithdrawalAccountType.UPI:
        return (
          <AddUPIDetails editData={activeEdit} callBack={handleCloseAddCard} />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if ((withdrawalAccountType as ILookupModel[]).length > 0) {
      setWithdrawalAccount(withdrawalAccountType[0]);
    }
  }, []);

  const loadSavedCards = async () => {
    try {
      setLoading(true);
      var response: IApiResponse = await _withdrawalService.withdrawalDetails();
      if (response.isSuccess) {
        const responseData = response.response as SavedAccountPageModel;
        setSavedAccounts(responseData);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPageData();

    async function loadPageData() {
      await loadSavedCards();
    }
  }, []);

  const _handleEdit = (editable: IWithdrawalModel) => {
    const accountType: ILookupModel[] = withdrawalAccountType.filter(
      (x: ILookupModel) => x.id.toString() === editable.accountType.toString()
    );

    setWithdrawalAccount({ ...accountType[0] });
    setActiveEdit(editable);
  };

  useEffect(() => {
    if (activeEdit && activeEdit.id > 0) {
      setOpenAddCard(true);
    }
  }, [activeEdit]);

  const deleteAccount = async () => {
    try {
      var response: IApiResponse = await _withdrawalService.delete(
        activeDeleteId
      );
      if (response.isSuccess) {
        setAlertConfig({
          description: response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            navigate("/myBankCards");
          },
        });
        setActiveDeleteId(0);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  const setAsPrimaryAccount = async () => {
    try {
      var response: IApiResponse = await _withdrawalService.setPrimaryAccount(
        isPrimary.id
      );
      if (response.isSuccess) {
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            navigate("/myBankCards");
          },
        });
        setIsPrimary({ id: 0 });
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Button onClick={handleOpenAddCard} className="floatR">
              <span><AddCircleOutlineRoundedIcon />
              Add Account</span>
            </Button>
            <Box>
              <Grid container spacing={1}>
                {savedAccounts &&
                  savedAccounts.upi &&
                  savedAccounts.upi.length > 0 &&
                  savedAccounts.upi.map((item: IWithdrawalModel) => (
                    <Grid
                      key={item.id}
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      xl={3}
                      className="bankCardHeight"
                    >
                      <Box className="card">
                        <Box className="card-inner">
                          <Box className="front">
                            <Box>
                              <img
                                alt="BetBhat - Map"
                                className="commonImage map-img"
                                src={require("../../assets/images/myBankCards/map.png")}
                              />
                              <Box className="row">
                                <img
                                  alt="BetBhat - Map"
                                  className="commonImage Chip"
                                  style={{ width: "30px" }}
                                  src={require("../../assets/images/myBankCards/chip.png")}
                                />
                                <img
                                  alt="BetBhat - Map"
                                  className="commonImage Chip"
                                  style={{ width: "30px" }}
                                  src={require("../../assets/images/myBankCards/upi.png")}
                                />
                              </Box>
                              <Box className="card-no">
                                <Typography className="cardValue">
                                  {item.upiId}
                                </Typography>
                              </Box>
                              <Box className="">
                                <Typography className="cardLabel card-holder">
                                  Phone Number
                                </Typography>
                                <Typography className="cardValue name">
                                  {item.phoneNumber}
                                </Typography>
                              </Box>
                            </Box>
                            {/* Actions Buttons */}
                            <Box className="bankCardActions">
                              <Box>
                                <IconButton
                                  aria-label="edit"
                                  className="primaryOverlayIconBtn"
                                  onClick={() => _handleEdit(item)}
                                >
                                  <EditRoundedIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="edit"
                                  className="primaryOverlayIconBtn"
                                  onClick={() => {
                                    if (item.isPrimary) {
                                      setToastConfig(
                                        ToastSeverity.Error,
                                        "Cannot delete the account set as primary.",
                                        true
                                      );
                                    } else {
                                      setActiveDeleteId(item.id);
                                    }
                                  }}
                                >
                                  <DeleteRoundedIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="primary"
                                  className={`primaryOverlayIconBtn ${
                                    item.isPrimary ? "primaryAccount" : ""
                                  }`}
                                  onClick={() => {
                                    if (!item.isPrimary) {
                                      setIsPrimary({
                                        id: item.id,
                                        primary: item.isPrimary,
                                      });
                                    } else {
                                      setToastConfig(
                                        ToastSeverity.Error,
                                        "Please set another account as primary to continue.",
                                        true
                                      );
                                    }
                                  }}
                                >
                                  {" "}
                                  {item.isPrimary ? (
                                    <CheckCircleRoundedIcon />
                                  ) : (
                                    <CheckRoundedIcon />
                                  )}
                                </IconButton>
                              </Box>
                            </Box>
                            {/* Actions Buttons */}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              <Grid container spacing={1}>
                {savedAccounts &&
                  savedAccounts.bankAccount &&
                  savedAccounts.bankAccount.length > 0 &&
                  savedAccounts.bankAccount.map((item: IWithdrawalModel) => (
                    <Grid
                      key={item.id}
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      xl={3}
                      className="bankCardHeight"
                    >
                      <Box className="card">
                        <Box className="card-inner">
                          <Box className="front">
                            <Box>
                              <img
                                alt="BetBhat - Map"
                                className="commonImage map-img"
                                src={require("../../assets/images/myBankCards/map.png")}
                              />
                              <Box className="row">
                                <img
                                  alt="BetBhat - Map"
                                  className="commonImage Chip"
                                  style={{ width: "30px" }}
                                  src={require("../../assets/images/myBankCards/chip.png")}
                                />
                                <img
                                  alt="BetBhat - UPI"
                                  className="commonImage bankLogo"
                                  src={require("../../assets/images/myBankCards/bank.png")}
                                />
                              </Box>
                              <Box className="row card-no">
                                <Typography className="cardValue">
                                  {item.accountNumber}
                                </Typography>
                              </Box>
                              <Box className="row">
                                <Box>
                                  <Typography className="cardLabel card-holder">
                                    Account Holder Name
                                  </Typography>
                                  <Typography className="cardValue name">
                                    {item.accountHolderName}
                                  </Typography>
                                </Box>
                                <Box className="textRight">
                                  <Typography className="cardLabel card-holder">
                                    IFSC Code
                                  </Typography>
                                  <Typography className="cardValue name">
                                    {item.ifscCode}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            {/* Actions Buttons */}
                            <Box className="bankCardActions">
                              <Box>
                                <IconButton
                                  aria-label="edit"
                                  className="primaryOverlayIconBtn"
                                  onClick={() => _handleEdit(item)}
                                >
                                  <EditRoundedIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="edit"
                                  className="primaryOverlayIconBtn"
                                  onClick={() => {
                                    if (item.isPrimary) {
                                      setToastConfig(
                                        ToastSeverity.Error,
                                        "Cannot delete the account set as primary.",
                                        true
                                      );
                                    } else {
                                      setActiveDeleteId(item.id);
                                    }
                                  }}
                                >
                                  <DeleteRoundedIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="primary"
                                  className={`primaryOverlayIconBtn ${
                                    item.isPrimary ? "primaryAccount" : ""
                                  }`}
                                  onClick={() => {
                                    if (!item.isPrimary) {
                                      setIsPrimary({
                                        id: item.id,
                                        primary: item.isPrimary,
                                      });
                                    } else {
                                      setToastConfig(
                                        ToastSeverity.Error,
                                        "Please set another account as primary to continue.",
                                        true
                                      );
                                    }
                                  }}
                                >
                                  {item.isPrimary ? (
                                    <CheckCircleRoundedIcon />
                                  ) : (
                                    <CheckRoundedIcon />
                                  )}
                                </IconButton>
                              </Box>
                            </Box>
                            {/* Actions Buttons */}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              {savedAccounts &&
                savedAccounts.bankAccount.length == 0 &&
                savedAccounts.upi.length == 0 && (
                  <Box className="noDataFound">
                    <Box className="noDataFoundPreview">
                      <img
                        alt="BetBhat - No Referrals"
                        className="commonImage"
                        src={require("../../assets/images/myBankCards/noCards.png")}
                      />
                    </Box>
                    <Typography component="a" className="noDataFoundLabel">
                      You currently have no account to withdraw. Click and add
                      account to continue withdrawal.
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>

          {/* Modal Popup Starts Here - Add New Card*/}
          <Modal
            className="commonModal"
            open={openAddCard}
            onClose={() => handleCloseAddCard(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="commonModalBox">
              <Box className="commonModalBoxInner">
                <Box id="modal-modal-title" className="commonModalHead">
                  <Grid container spacing={1}>
                    <Grid item xs={11} md={11} lg={11}>
                      <Typography className="modalTitle">
                        Add/Edit Bank Details
                      </Typography>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}>
                      <span className="modalClose">
                        <CloseRoundedIcon
                          onClick={() => handleCloseAddCard(null)}
                        />
                      </span>
                    </Grid>
                  </Grid>
                </Box>
                <Box id="modal-modal-description" className="commonModalBody">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl fullWidth className="marB20">
                        <InputLabel id="type">Type</InputLabel>
                        {withdrawalAccount.id > 0 && (
                          <Select
                            labelId="type"
                            id="type"
                            label="Type"
                            onChange={(evt: SelectChangeEvent) => {
                              const accountType: ILookupModel[] = withdrawalAccountType.filter(
                                (x: ILookupModel) =>
                                  x.id.toString() ===
                                  evt.target.value.toString()
                              );

                              setWithdrawalAccount({ ...accountType[0] });
                            }}
                            value={withdrawalAccount.id.toString()}
                            disabled={activeEdit !== null}
                          >
                            {withdrawalAccountType &&
                              withdrawalAccountType.map(
                                (account: ILookupModel) => (
                                  <MenuItem
                                    key={account.id}
                                    value={account.id.toString()}
                                  >
                                    {account.value}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {withdrawalAccount && (
                    <RenderEvent type={withdrawalAccount} />
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>
          {/* Modal Popup Ends Here - Add New Card*/}

          {/* Modal Popup Starts Here - Delete Card*/}
          {activeDeleteId > 0 && (
            <Modal
              className="commonModal"
              open={activeDeleteId > 0}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="commonModalBox">
                <Box className="commonModalBoxInner">
                  <Box id="modal-modal-title" className="commonModalHead">
                    <Grid container spacing={1}>
                      <Grid item xs={11} md={11} lg={11}>
                        <Typography className="modalTitle">
                          Delete Bank Card
                        </Typography>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1}>
                        <span className="modalClose">
                          <CloseRoundedIcon
                            onClick={() => setActiveDeleteId(0)}
                          />
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box id="modal-modal-description" className="commonModalBody">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography>
                          Are you sure you want to delete?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box id="modal-modal-footer" className="commonFooter">
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        className="primaryLineBtn"
                        onClick={() => setActiveDeleteId(0)}
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="contained"
                        className="primaryFillBtn"
                        onClick={() => deleteAccount()}
                      >
                        <span>Delete</span>
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}

          {/* Modal Popup Ends Here - Delete Card*/}

          <Dialog
            open={isPrimary.id > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Set as Primary?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure want to {isPrimary.primary ? "remove " : "set "}{" "}
                account as primary?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsPrimary({ id: 0 })}><span>Cancel</span></Button>
              <Button
                onClick={() => {
                  setAsPrimaryAccount();
                }}
                autoFocus
              >
                <span>Confirm</span>
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Box>
  );
};
export default MyBankCards;

import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import PageProps from "../../models/PageProps.interface";
import { useDispatch, useSelector } from "react-redux";

//Libraries Import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//Icons Import
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import LookupService from "../../services/lookup/lookup.service";
//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Import Css
import "../rechargeDetails/rechargeDetails.screen.style.css";
import { IRechargePayments } from "../../interface/BusinessModels/IRechargePayments";
import RechargeService from "../../services/recharge/recharge.service";
import { API_ERROR_STANDARD_MESSAGE } from "../../constants/DBConstants.contant";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { ToastContext } from "../../context/toast.context";
import { LoadingContext } from "../../context/loading.context";
import { IApplicationAgentRegistrationModel } from "../../interface/BusinessModels/IApplicationAgentRegistrationModel";
import UserService from "../../services/user/user.service";
import { AppConfig } from "../../constants/AppConfig.constant";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import AgentUsers from "../../components/agentUsers/agentUsers.component";

const RechargeDetails: FunctionComponent<PageProps> = ({ title }) => {
  const [rechargePayments, setRechargePayments] = useState<IRechargePayments>();
  const [agentUsers, setAgentUsers] = useState<
    IApplicationAgentRegistrationModel[]
  >([]);
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const _lookupService = new LookupService({});
  const _rechargeService = new RechargeService({});
  const _userService = new UserService({});
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const getRechargePayments = async (rechargeId: string) => {
    try {
      await getApplicationAgents();
      const response: IApiResponse = await _rechargeService.getrechargePayments(
        rechargeId
      );

      setRechargePayments(response.response as IRechargePayments);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const rchId = params.get("rchId");
    if (rchId != null) {
      setLoading(true);
      getRechargePayments(rchId);
    }
  }, []);

  const getApplicationAgents = async () => {
    const agentUsersList: IApiResponse = await _userService.getAgentUsers();
    const agentUsersListData = agentUsersList.response as IApplicationAgentRegistrationModel[];
    setAgentUsers(agentUsersListData);
  };

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard saperatorSpacing agentsTeam">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8} className="verticalCenter">
                  <Box className="commonCenterCard">
                    {" "}
                    <Typography className="sectionTitle">
                      Please contact below {AppConfig.applicationName} support
                      team to speed up your confirmation.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="walletBox">
                  <Box className="bannerImage">
                    <img
                      alt="BetBhat - Wallet"
                      className=""
                      src={require("../../assets/images/myWallet/recharge.png")}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              {rechargePayments && rechargePayments && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Recharge Id
                      </Typography>
                      <Typography className="commonValue">
                        {rechargePayments.rechargeId}
                      </Typography>
                    </Box>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">Type</Typography>
                      <Typography className="commonValue">
                        {rechargePayments.type}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Recharge Amount
                      </Typography>
                      <Typography className="commonValue">
                        <span></span> {rechargePayments.amount}
                      </Typography>
                    </Box>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Payment Method
                      </Typography>
                      <Typography className="commonValue">
                        {rechargePayments.transactionType}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Phone Number
                      </Typography>
                      <Typography className="commonValue">
                        {rechargePayments.phoneNumber}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Payment sent to {rechargePayments.transactionType}
                      </Typography>
                      <Typography className="commonValue">
                        {rechargePayments.transactionFields &&
                          (() => {
                            let transactionFields = [];

                            try {
                              transactionFields = JSON.parse(
                                rechargePayments.transactionFields
                              );
                            } catch (error) {
                              console.error("Invalid JSON string:", error);
                            }

                            return (
                              <>
                                {transactionFields[0] && (
                                  <p>
                                    {transactionFields[0].key}:{" "}
                                    {transactionFields[0].value}
                                  </p>
                                )}
                                <br />
                                {transactionFields[1] && (
                                  <p>
                                    {transactionFields[1].key}:{" "}
                                    {transactionFields[1].value}
                                  </p>
                                )}
                              </>
                            );
                          })()}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Reference Number/Txn ID/UTR
                      </Typography>
                      <Typography className="commonValue">
                        {rechargePayments.transactionUTRId}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Recharge Status
                      </Typography>
                      <Typography className="commonValue">
                        {rechargePayments.rechargeStatus}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className="commonLabelValue nextLabelValue">
                      <Typography className="commonLabel">
                        Created Date Time
                      </Typography>
                      <Typography className="commonValue">
                        {rechargePayments.createdOn.toLocaleString()}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box>
              <Box>
                {/* Agents Team Section Starts Here */}
                <Box>
                  <AgentUsers />
                </Box>
                {/* Agents Team Section Ends Here */}
              </Box>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default RechargeDetails;

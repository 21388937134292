import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from "react";
import PageProps from "../../models/PageProps.interface";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Switch from "@mui/material/Switch";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@mui/material/Modal";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";
import "../allUsers/allUsers.screen.style.css";
import * as yup from "yup";
import UserService from "../../services/user/user.service";
import { IUserResponseModel } from "../../interface/Response/IUserResponseModel";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { IAllUsersReponseModel } from "../../interface/Response/IAllUsersReponseModel";
import { IPaginationRequest } from "../../interface/Request/IPaginationRequest";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import { IUserRegistrationModel } from "../../interface/BusinessModels/IUserRegistrationModel";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  ROLES_TYPE,
} from "../../constants/DBConstants.contant";
import { getCountries } from "../../actions/lookup.actions";
import { useDispatch, useSelector } from "react-redux";
import FormHelperText from "@mui/material/FormHelperText";
import { LoadingContext } from "../../context/loading.context";
import { ICountryModel } from "../../interface/BusinessModels/ICountryModel";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import LookupService from "../../services/lookup/lookup.service";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllUsers: FunctionComponent<PageProps> = ({ title }) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const _lookupService = new LookupService({});
  const _userService = new UserService({});
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [openAddCard, setOpenAddCard] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const handleOpenAddCard = () => setOpenAddCard(true);
  const handleCloseAddCard = () => setOpenAddCard(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Password Show Hide
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [roles, setRoles] = useState<ILookupModel[]>([]);
  const [countryId, setCountryId] = useState(0);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const initializeCountries = () => dispatch<any>(getCountries());
  const { countries } = useSelector((state: any) => state.lookupReducer);
  const [allUsersModel, setAllUsersModel] = useState<IAllUsersReponseModel>({
    users: [],
    pagination: {
      totalPages: 0,
      totalRecords: 0,
      pageNumber: 0,
      pageSize: 0,
    },
  });

  useEffect(() => {
    getRoles();

    async function getRoles() {
      setLoading(true);
      try {
        const rolesRes: IApiResponse = await _lookupService.getConfigs(
          ROLES_TYPE
        );
        const rolesParsed: ILookupModel[] = rolesRes.response as ILookupModel[];
        setRoles(rolesParsed);
      } catch (error) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      }
      setLoading(false);
    }
  }, []);

  const InitialValues: IUserRegistrationModel = {
    email: "bhat@gmail.com",
    countryCode: "",
    phoneCode: "",
    phoneNumber: "",
    password: "",
    referredBy: "",
    roleId: 0,
  };

  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const SignupSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("This field is required"),
    countryCode: yup.string().required("Country Code is required."),
    phoneCode: yup.string().required("Phone Code is required."),
    phoneNumber: yup.string().required("Phone Number is required."),
    roleId: yup
      .number()
      .moreThan(0, "Select valid Role.")
      .required("Role is required."),
    password: yup
      .string()
      .required("Password is required.")
      .min(8, "Should be minimum of 8 characters"),
    referredBy: yup.string(),
  });
  //Password Show Hide
  const _applicationHelperService = new ApplicationHelperService({});

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const _handleSignUp = async (values: IUserRegistrationModel) => {
    try {
      // const isValid = await SignupSchema.isValid(values);
      // if (isValid) {
      if (confirmPassword != values.password) {
        setToastConfig(
          ToastSeverity.Error,
          "Password & confirm password doesn't match.",
          true
        );
      } else {
        var response: IApiResponse = await _userService.registerUser({
          ...values,
          phoneNumber: values.phoneNumber.toString(),
        });
        if (!response.isSuccess) {
          setToastConfig(ToastSeverity.Error, response.message, true);
        } else {
          handleCloseAddCard();
          setConfirmPassword("");
          setCountryId(0);
          setAlertConfig({
            description: `${response.message}|${response.response.user.appId}|${values.password}`,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              getAllUsers();
            },
          });
        }
      }
      //}
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  const [allUsersRequest, setAllUsersRequest] = useState<IPaginationRequest>({
    pageNumber: 1,
    pageSize: 1000000,
  });
  useEffect(() => {
    setLoading(true);
    initializeCountries();
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   getAllUsers();
  // }, [allUsersRequest]);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const getAllUsers = async () => {
    setLoading(true);
    var response: IApiResponse = await _userService.getAllUsers(
      allUsersRequest
    );
    setLoading(false);
    if (!response.isSuccess) {
      setToastConfig(ToastSeverity.Error, response.message, true);
    } else {
      setAllUsersModel(response.response as IAllUsersReponseModel);
    }
  };

  const columns: GridColDef[] = [
    { field: "appId", headerName: "Id", minWidth: 150 },
    { field: "email", headerName: "Email", minWidth: 150 },
    { field: "role", headerName: "Role", minWidth: 150 },
    { field: "phoneCode", headerName: "Phone Code", minWidth: 150 },
    { field: "phoneNumber", headerName: "Phone Number", minWidth: 150 },
    {
      field: "balance",
      headerName: "Balance",
      // renderCell: (params) => {
      //   return (
      //     <span>
      //       {/* {`${params.row.walletBalance.balance} ${params.row.walletBalance.currency}`} */}
      //       {`${params.row.walletBalance.balance}`}
      //     </span>
      //   );
      // },
      minWidth: 150,
    },
    {
      field: "active",
      headerName: "Is Active",
      renderCell: (params) => {
        return params.row.isActive ? (
          <ToggleOnIcon
            color="success"
            onClick={() => deleteUser(params.row)}
          />
        ) : (
          <ToggleOffIcon color="error" onClick={() => deleteUser(params.row)} />
        );
      },
      minWidth: 150,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      renderCell: (params) => {
        return (
          <span>
            {_applicationHelperService.getExpandedDateTimeFormat(
              params.row.createdOn
            )}
          </span>
        );
      },
      minWidth: 300,
    },
    {
      field: "action",
      headerName: "Transactions",
      renderCell: (params) => {
        return (
          <VisibilityOutlinedIcon
            onClick={() => navigate("/myWallet", { state: params.row.userId })}
          />
        );
      },
      minWidth: 150,
    },
  ];

  const deleteUser = async (userModel: any) => {
    var response: IApiResponse = await _userService.deleteUser(userModel.appId);
    if (!response.isSuccess) {
      setToastConfig(ToastSeverity.Error, response.message, true);
    } else {
      setAlertConfig({
        description: response.message,
        toastSeverity: ToastSeverity.Success,
        isShow: true,
        callBack: () => {
          setAlertConfig(DEFAULT_ALERT_CONFIG);
          getAllUsers();
        },
      });
    }
  };

  function Row(props: { row: IUserResponseModel; rowNumber: number }) {
    const { row, rowNumber } = props;
    return (
      <React.Fragment>
        <TableRow key={rowNumber} sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell key={0} data-label="S.No">
            {rowNumber}
          </TableCell>
          <TableCell key={1} data-label="App Id">
            {row.appId}
          </TableCell>
          <TableCell key={2} data-label="Email">
            {row.email}
          </TableCell>
          <TableCell key={3} data-label="Role">
            {row.role}
          </TableCell>
          <TableCell key={4} data-label="Phone Code">
            {row.phoneCode}
          </TableCell>
          <TableCell key={5} data-label="Phone Number">
            {row.phoneNumber}
          </TableCell>
          {/* <TableCell
            key={6}
            data-label="Balance"
          >{`${row.walletBalance.balance} ${row.walletBalance.currency}`}</TableCell> */}
          <TableCell
            key={6}
            data-label="Balance"
          >{`${row.walletBalance.balance}`}</TableCell>
          <TableCell key={7} data-label="Status">
            {row.isActive ? (
              <ToggleOnIcon
                color="success"
                onClick={() => deleteUser(props.row)}
              />
            ) : (
              <ToggleOffIcon
                color="error"
                onClick={() => deleteUser(props.row)}
              />
            )}
          </TableCell>

          <TableCell key={8} data-label="Created On">
            {_applicationHelperService.getExpandedDateTimeFormat(row.createdOn)}
          </TableCell>
          <TableCell key={9} data-label="Transactions">
            <VisibilityOutlinedIcon
              onClick={() => navigate("/myWallet", { state: row.userId })}
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                  <Typography>
                    All Users{" "}
                    <span>({allUsersModel.pagination.totalRecords})</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3} className="boxRight">
                  <Button onClick={handleOpenAddCard} className="primaryColor">
                    <AddCircleOutlineRoundedIcon />
                    <span>Create New User</span>
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box className="saperationSection" sx={{ display: "none" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card>
                      <CardContent>
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={10} md={10} lg={10}>
                              <Typography className="cardTitle whiteColor">
                                1463490755
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              md={2}
                              lg={2}
                              className="boxRight switchTopRight"
                            >
                              <Box className="">
                                <Switch disabled defaultChecked />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">Email</Typography>
                          <Typography className="commonValue">
                            krishna123@gmail.com
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">Role</Typography>
                          <Typography className="commonValue">User</Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Phone Code
                          </Typography>
                          <Typography className="commonValue">93</Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Phone Number
                          </Typography>
                          <Typography className="commonValue">
                            7897897890
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Balance
                          </Typography>
                          <Typography className="commonValue">0AFN</Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Created On
                          </Typography>
                          <Typography className="commonValue">
                            April 22, 2024 06:33 PM
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {isSmScreen ? (
              <Box>
                <TextField
                  style={{ marginBottom: 10 }}
                  label="Search AppId"
                  variant="outlined"
                  onChange={(event: any) => setSearchText(event.target.value)}
                  fullWidth
                  value={searchText}
                />
                <TableContainer
                  component={Paper}
                  className="commonTable responsiveTable"
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          width={60}
                          key={0}
                          variant="head"
                          sx={{
                            backgroundColor: "background.paper",
                          }}
                        >
                          {"S.No"}
                        </TableCell>
                        {columns.map((column, index) => (
                          <TableCell
                            width={`${column.width}%`}
                            key={index + 1}
                            variant="head"
                            sx={{
                              backgroundColor: "background.paper",
                            }}
                          >
                            {column.headerName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allUsersModel.users
                        .filter((x) => x.appId.includes(searchText))
                        .map((row, index) => (
                          <Row
                            row={row}
                            rowNumber={
                              (allUsersModel.pagination.pageNumber - 1) *
                                allUsersModel.pagination.pageSize +
                              (index + 1)
                            }
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={allUsersModel.pagination.totalRecords}
                  rowsPerPage={allUsersModel.pagination.pageSize}
                  page={allUsersModel.pagination.pageNumber - 1}
                  onPageChange={(event, page) => {
                    setAllUsersRequest({
                      ...allUsersRequest,
                      pageNumber: page + 1,
                    });
                  }}
                />
              </Box>
            ) : (
              <DataGrid
                getRowId={(row) => row.appId}
                rows={allUsersModel.users}
                columns={columns}
                slots={{
                  toolbar: GridToolbar,
                }}
                pagination
                paginationMode="client"
                rowCount={allUsersModel.pagination.totalRecords}
                onPaginationModelChange={(model: GridPaginationModel) => {
                  setAllUsersRequest({
                    ...allUsersRequest,
                    pageNumber: model.page + 1,
                  });
                }}
                pageSizeOptions={[25, 50, 100]}
                // paginationModel={{
                //   page: allUsersModel.pagination.pageNumber - 1,
                //   pageSize: allUsersModel.pagination.pageSize,
                // }}
              />
            )}
          </Box>

          <Box className="pageViewFooter">
            <Footer />
          </Box>

          {/* Modal Popup Starts Here - Add New Card*/}

          <Modal
            className="commonModal"
            open={openAddCard}
            onClose={handleCloseAddCard}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Formik
              initialValues={{ ...InitialValues }}
              validationSchema={SignupSchema}
              onSubmit={(values, { setSubmitting }) => {
                _handleSignUp(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="height100">
                  <Box className="commonModalBox">
                    <Box className="commonModalBoxInner">
                      <Box id="modal-modal-title" className="commonModalHead">
                        <Grid container spacing={2}>
                          <Grid item xs={11} md={11} lg={11}>
                            <Typography className="modalTitle">
                              Add User
                            </Typography>
                          </Grid>
                          <Grid item xs={1} md={1} lg={1}>
                            <span className="modalClose">
                              <CloseRoundedIcon onClick={handleCloseAddCard} />
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        id="modal-modal-description"
                        className="commonModalBody"
                      >
                        <Grid container spacing={2}>
                          {/* <Grid item xs={12} md={12} lg={12}>
                              <TextField
                                type="email"
                                id="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                value={values.email}
                              />

                              {errors.email && touched.email && (
                                <FormHelperText className="errorMessage">
                                  {errors.email}
                                </FormHelperText>
                              )}
                            </Grid> */}

                          <Grid item xs={12} md={12} lg={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="outlined-country-dropdown-label">
                                Country
                              </InputLabel>
                              <Select
                                labelId="outlined-country-dropdown-label"
                                id="country-dropdown"
                                label="Country"
                                value={countryId.toString()}
                                onChange={(evt: SelectChangeEvent) => {
                                  if (evt.target.value === "0") {
                                    values.phoneCode = "";
                                    values.countryCode = "";
                                  } else {
                                    const country: ICountryModel[] = countries.filter(
                                      (x: ICountryModel) =>
                                        x.id.toString() ===
                                        evt.target.value.toString()
                                    );
                                    values.phoneCode = country[0].phoneCode;
                                    values.countryCode = country[0].code;
                                  }
                                  setCountryId(+evt.target.value);
                                }}
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {countries &&
                                  countries.map((country: ICountryModel) => (
                                    <MenuItem
                                      key={country.id}
                                      value={country.id.toString()}
                                    >
                                      {country.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {errors.countryCode && touched.countryCode && (
                                <FormHelperText className="errorMessage">
                                  {errors.countryCode}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="outlined-country-dropdown-label">
                                Role
                              </InputLabel>
                              <Select
                                labelId="outlined-country-dropdown-label"
                                id="country-dropdown"
                                label="Role"
                                value={values.roleId.toString()}
                                onChange={(evt: SelectChangeEvent) => {
                                  if (evt.target.value.toString() === "0") {
                                    values.phoneCode = "";
                                    values.countryCode = "";
                                  } else {
                                    setFieldValue(
                                      "roleId",
                                      evt.target.value.toString()
                                    );
                                  }
                                }}
                              >
                                <MenuItem value={0}>Select</MenuItem>
                                {roles &&
                                  roles.map((role: ILookupModel) => (
                                    <MenuItem
                                      key={role.id}
                                      value={role.id.toString()}
                                      disabled={role.id <= userInfo.user.roleId}
                                    >
                                      {role.value}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {errors.roleId && touched.roleId && (
                                <FormHelperText className="errorMessage">
                                  {errors.roleId}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <FormControl fullWidth>
                              <TextField
                                id="countryCode"
                                label="Code"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={values.phoneCode}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={10} md={10} lg={10}>
                            <TextField
                              type="number"
                              id="phoneNumber"
                              label="Phone Number"
                              variant="outlined"
                              fullWidth
                              onChange={handleChange}
                              value={values.phoneNumber.toString()}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <FormHelperText className="errorMessage">
                                {errors.phoneNumber}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel htmlFor="password-label">
                                Password
                              </InputLabel>
                              <OutlinedInput
                                id="password"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Password"
                                onChange={handleChange}
                                value={values.password}
                              />
                              {errors.password && touched.password && (
                                <FormHelperText className="errorMessage">
                                  {errors.password}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel htmlFor="outlined-adornment-confirm-password">
                                Confirm Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confirm Password"
                                onChange={(event: any) =>
                                  setConfirmPassword(event.target.value)
                                }
                                value={confirmPassword}
                              />
                              {errors.password &&
                                touched.password &&
                                !confirmPassword && (
                                  <FormHelperText className="errorMessage">
                                    Confirm {errors.password}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <TextField
                              id="referredBy"
                              label="Referral Code(Optional)"
                              variant="outlined"
                              fullWidth
                              onChange={handleChange}
                              value="2333TYHTF"
                              disabled={true}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box id="modal-modal-footer" className="commonFooter">
                        <Stack spacing={2} direction="row">
                          <Button
                            type="submit"
                            variant="contained"
                            className="primaryFillBtn width100"
                          >
                            <span>Submit</span>
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Modal>
        </Box>
        {alertConfig && alertConfig.isShow && (
          <Dialog
            open={alertConfig.isShow}
            className="alertModel"
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <Box className="alertIcon">
              <img
                alt="BetBhat"
                className=""
                src={require("../../assets/images/login/success.png")}
              />
              {/* <CheckCircleOutlineRoundedIcon className="successColor" /> */}
            </Box>
            {alertConfig.title && (
              <DialogTitle className="pageHead">
                {alertConfig.title}
              </DialogTitle>
            )}
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Typography className="alertTitle">
                  {alertConfig.description &&
                    alertConfig.description.split("|")[0]}
                </Typography>
                {alertConfig.description &&
                  alertConfig.description.split("|")[1] && (
                    <Box>
                      <Typography>
                        <span>User Id</span> :{" "}
                        <span className="commonValue1">
                          {alertConfig.description &&
                            alertConfig.description.split("|")[1]}
                        </span>
                      </Typography>
                    </Box>
                  )}
                {alertConfig.description &&
                  alertConfig.description.split("|")[2] && (
                    <Box>
                      <Typography>
                        <span>Password</span> :{" "}
                        <span className="commonValue1">
                          {alertConfig.description &&
                            alertConfig.description.split("|")[2]}
                        </span>
                      </Typography>
                    </Box>
                  )}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="alertFooter">
              <Button
                variant="outlined"
                onClick={() => alertConfig.callBack()}
                className="alertAction"
              >
                <span>Okay!</span>
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </Box>
  );
};
export default AllUsers;

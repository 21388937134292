import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Footer from "../../components/footer/footer.component";
import "../resultsHistory/resultsHistory.screen.style.css";
import PlayGameService from "../../services/playGame/playGame.service";
import { IHomepageGameDataResponseModel } from "../../interface/Response/IHomepageGameDataResponseModel";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import PageProps from "../../models/PageProps.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingContext } from "../../context/loading.context";
import TopNav from "../../components/topNav/topNav.component";
import Container from "@mui/material/Container";

//Icons
import PollRoundedIcon from "@mui/icons-material/PollRounded";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import { IGamesConfigModel } from "../../interface/BusinessModels/IGamesConfigModel";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { ToastContext } from "../../context/toast.context";
import { API_ERROR_STANDARD_MESSAGE } from "../../constants/DBConstants.contant";
import { IAlertDialogConfig } from "../../interface/IAlertDialogConfig";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import ExportService from "../../services/export/export.service";

const ResultsHistory: FunctionComponent<PageProps> = ({ title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const _playGameService = new PlayGameService({});
  const [resultHistory, setResultHistory] = useState<
    IHomepageGameDataResponseModel[]
  >();
  const { setLoading } = useContext(LoadingContext);
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const _gamesConfigService = new GamesConfigService({});
  const _exportService = new ExportService({});

  const [gamesConfig, setGamesConfig] = useState<IGamesConfigModel[]>();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [activeGameConfig, setActiveGameConfig] = useState<number>(
    location.state === null ? 0 : location.state
  );

  useEffect(() => {
    if (activeGameConfig > 0) {
      setResultHistory([]);
      getResultsHistory();
    }
  }, [activeGameConfig]);

  const getResultsHistory = async () => {
    setLoading(true);
    try {
      var response: IApiResponse = await _playGameService.getLotteryHistory(
        activeGameConfig
      );
      var histories = response.response as IHomepageGameDataResponseModel[];
      setResultHistory(histories);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getAvailableGames();
  }, []);

  const getAvailableGames = async () => {
    try {
      setLoading(true);
      const response: IApiResponse = await _gamesConfigService.getAvailableGames();
      setGamesConfig(response.response as IGamesConfigModel[]);
      if (location.state === null) {
        setActiveGameConfig((response.response as IGamesConfigModel[])[0].id);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  const DownloadResultChart = async () => {
    try {
      setLoading(true);
      const response = await _exportService.downloadResultChart(
        activeGameConfig
      );
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
    setLoading(false);
  };

  return (
    <Box className="pageMain singlePageLayout">
      <Box className="pageRight">
        <Box className="pageHead">
          <Box className="landingPageNavBar">
            <Container className="customContainer">
              <Grid container spacing={1}>
                <Grid item xs={2} md={2} lg={2} className="">
                  <Box className="HeaderLogo">
                    <img
                      alt="BetBhat - Brand Logo"
                      className="commonImage"
                      src={require("../../assets/images/common/logo.png")}
                      onClick={() => navigate("/")}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={10}
                  md={10}
                  lg={10}
                  className="singlePageLayoutNavbarActions"
                >
                  <TopNav title={title.split("|")[1].trim()} />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  {location.state === null && (
                    <Typography className="pageTitle ">
                      Result History
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} md={3} lg={3} className="customTopSpacing">
                  {location.state === null && (
                    <FormControl fullWidth>
                      <InputLabel id="lotteryType">Game</InputLabel>
                      <Select
                        labelId="lotteryType"
                        id="lotteryType"
                        label="lotteryType"
                        value={activeGameConfig.toString()}
                        onChange={(evt: SelectChangeEvent) => {
                          setActiveGameConfig(+evt.target.value);
                        }}
                      >
                        {gamesConfig &&
                          gamesConfig.map((option: IGamesConfigModel) => (
                            <MenuItem
                              key={option.id}
                              value={option.id.toString()}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

                <Grid item xs={12} md={3} lg={3} className="verticalCenter">
                  <FormControl fullWidth>
                    <Button
                      variant="contained"
                      className="primaryFillBtn sectionBtn floatR"
                      onClick={() => DownloadResultChart()}
                    >
                      <span><PollRoundedIcon /> &nbsp; Download Chart</span>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                {resultHistory &&
                  resultHistory.length > 0 &&
                  resultHistory.map(
                    (item: IHomepageGameDataResponseModel, index: number) => (
                      <Grid
                        key={index}
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                      >
                        <Box className="commonCard resultHistoryCard textDecorationNone">
                          <Card>
                            <CardContent>
                              <Box className="commonBgCard">
                                <Typography className="gameDate cardTitle textCenter">
                                  {item.title}
                                </Typography>
                                <Box className="prizeCup">
                                  <img
                                    alt="BetBhat - First Prize"
                                    className="commonImage"
                                    src={require("../../assets/images/resultsHistory/fisrtPrize.png")}
                                  />
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <Box className="firstPrizeBox">
                                      <Box className="textCenter prizeType">
                                        First Prize
                                      </Box>
                                      <Box className="numberCircleGroup">
                                        {item.firstPrize
                                          .split("")
                                          .map((x: string, index: number) => (
                                            <span
                                              key={index}
                                              className="numberCircle"
                                            >
                                              {x}
                                            </span>
                                          ))}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6} lg={6}>
                                    <Box className="secondPrizeBox">
                                      <Box className="textCenter prizeType">
                                        3-Up
                                      </Box>
                                      <Box className="numberCircleGroup">
                                        {item.threeUpStraight
                                          .split("")
                                          .map((x: string, index: number) => (
                                            <span
                                              key={index}
                                              className="numberCircle"
                                            >
                                              {x}
                                            </span>
                                          ))}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6} md={6} lg={6}>
                                    <Box className="thirdPrizeBox">
                                      <Box className="textCenter prizeType">
                                        2-Down
                                      </Box>
                                      <Box className="numberCircleGroup">
                                        {item.twoDownStraight
                                          .split("")
                                          .map((x: string, index: number) => (
                                            <span
                                              key={index}
                                              className="numberCircle"
                                            >
                                              {x}
                                            </span>
                                          ))}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    )
                  )}
              </Grid>
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
      {alertConfig && alertConfig.isShow && (
        <AlertDialog
          alertConfig={alertConfig}
          callBack={alertConfig.callBack}
        />
      )}
    </Box>
  );
};
export default ResultsHistory;
function setGamesConfig(arg0: IGamesConfigModel[]) {
  throw new Error("Function not implemented.");
}

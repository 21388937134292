import React, { FunctionComponent } from "react";
import PageProps from "../../models/PageProps.interface";

//Gadgets
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

//Gadgest - List
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

//Component Import
import Footer from "../../components/footer/footer.component";

//Icons
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import AddLinkRoundedIcon from "@mui/icons-material/AddLinkRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

//Css
import "../home/home.screen.style.css";
import { useSelector } from "react-redux";
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import { AppConfig } from "../../constants/AppConfig.constant";

const Home: FunctionComponent<PageProps> = ({ title }) => {
  const { userInfo } = useSelector((state: any) => state.authReducer);

  const { user } = userInfo;

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>

      <Box className="container-fluid">
        <Box className="heroSection">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}></Grid>
          </Grid>
        </Box>
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="announcementsSection"></Box>
        <Box className="playStoreSection"></Box>

        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">Dahboard</Typography>
                    </Box> */}
          <Box className="pageViewBody pageDashboard commonScroll">
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={9}
                  className="dashboardBannerContent"
                >
                  <Box className="saperatorSpacing">
                    <Typography className="dashboardBannerTitle">
                      Hello <span className="primaryColor">{user.email}</span>
                    </Typography>
                    <Typography className="normalText">
                      Welcome to {AppConfig.applicationName}
                    </Typography>
                  </Box>
                  <Box>
                    <Stack spacing={2} direction="row">
                      <Typography className="saperatorSpacing">
                        <span className="floatL">
                          <MailRoundedIcon />
                        </span>
                        <span>gsiva413@gmail.com</span>
                      </Typography>
                      <Typography className="saperatorSpacing">
                        <span className="floatL">
                          <AddLinkRoundedIcon />
                        </span>
                        <span>
                          <a
                            href={
                              window.location.origin + "/signup?ref=1411851985"
                            }
                            className="normalText"
                          >
                            {window.location.origin}/signup?ref=
                            {user.referralCode}
                          </a>
                        </span>
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Box className="bannerImage">
                    <img
                      alt="BetBhat - Wallet"
                      className=""
                      src={require("../../assets/images/dashboard/user.png")}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4} lg={4}>
                  <Box className="saperatorSpacing">
                    <Card className="appIdCard">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                User Id
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {user.appId}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <AccountCircleRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Box className="saperatorSpacing">
                    <Card className="referralCard">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Referrals
                              </Typography>
                              <Typography className="dashboardCardValue">
                                131
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <PeopleAltRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Box className="saperatorSpacing">
                    <Card className="balanceCard">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Balance
                              </Typography>
                              <Typography className="dashboardCardValue">
                                95326.85
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <AccountBalanceWalletRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Box className="commonCard">
                    <Card>
                      <CardContent className="padB0">
                        <Typography className="sectionTitle">
                          How It Works?
                        </Typography>
                        <Box>
                          <ul className="commonUL normalText">
                            <li>
                              Share your unique referral code with your friends.
                            </li>
                            <li>
                              Ask your friends to register and verify account.
                            </li>
                            <li>
                              Earn rewards as your referrals play games
                              everytime
                            </li>
                          </ul>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Link
                          href="https://wa.me/918106688579"
                          className="whatsappBtn"
                          target="_blank"
                        >
                          <span className="floatL">Share</span>{" "}
                          <span>
                            <WhatsAppIcon />
                          </span>
                        </Link>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box></Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;

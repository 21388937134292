import React, { FunctionComponent, useEffect, useState } from "react";
import PageProps from "../../models/PageProps.interface";

//Gadgets
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Marquee from "react-fast-marquee";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import YouTube from "react-youtube";

//Gadgest - List
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

//Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "../landingPage/countdownTimer";
//Component Import
import MainFooter from "../../components/mainFooter/mainFooter.component";
import Footer from "../../components/footer/footer.component";

import "../landingPageV2/landingPageV2.screen.style.css";
import PlayGameService from "../../services/playGame/playGame.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IHomepageGameDataResponseModel } from "../../interface/Response/IHomepageGameDataResponseModel";
import { AppConfig } from "../../constants/AppConfig.constant";
import { useLanguage } from "../../screens/LanguageProvider";
import GoogleTranslate from "../googleTranslate";

const LandingPageV2: FunctionComponent<PageProps> = ({ title }) => {
  // YouTube video options
  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      autoplay: 0, // Autoplay the video
      controls: 1, // Show player controls
      mute: 0, // Mute the video
      modestbranding: 1, // Remove YouTube logo from player
      fs: 1, // Show fullscreen button
      rel: 0, // Do not show related videos at the end
    },
  };

  const [homeGameData, setHomeGameDate] = useState<
    IHomepageGameDataResponseModel[]
  >();

  // YouTube video ID
  const videoId = "vH864uS3Juk";
  const navigate = useNavigate();
  const { language, toggleLanguage } = useLanguage();
  const _playGameService = new PlayGameService({});
  const content = {
    EN: {
      welcome: "Welcome to Betbhat!",
      play: "Play Thailottery Online",
    },
    TH: {
      welcome: "ยินดีต้อนรับสู่ Betbhat!",
      play: "เล่นหวยออนไลน์",
    },
  };

  useEffect(() => {
    getGameData();
    async function getGameData() {
      var response: IApiResponse = await _playGameService.getHomeGameData();
      var homepageData = response.response as IHomepageGameDataResponseModel[];
      setHomeGameDate(homepageData);
    }
  }, []);

  return (
    <Box className="landingV2">
      <Box className="heroSection">
        <Container className="heroSectionContainer">
          <Box className="topNavbar">
            <Box>
              <img
                alt="BetBhat - Flag"
                className="mainLogo"
                src={require("../../assets/images/landingPage2V2/logoNew.png")}
              />
            </Box>
            <Box className="topNavLinks">
              <Link href="#" className="linkNav">
                Home
              </Link>
              <Link href="#" className="linkNav">
                About Us
              </Link>
              <Link href="#" className="linkNav">
              <GoogleTranslate />
              </Link>
            </Box>
            <Box>
              <Button
                variant="contained"
                className="primaryBtn btnRightSpacing"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/login");
                }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                className="secondaryBtn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/signup");
                }}
              >
                Signup
              </Button>
             
            </Box>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Box className="contentCenter heroSectionContent">
                  <Typography className="secondaryTitle">
                    BetBhat Lottery
                  </Typography>
                  <h1 className="mainTitle">Welcome to Thailand Betbhat</h1>
                  <Typography className="commonText">
                    Thailand’s National Lottery, managed by the Government
                    Lottery Office, is drawn on the 1st and 16th of each month
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className="">
                <Box className="">
                  <img
                    alt="BetBhat - Flag"
                    className="rotatingImage"
                    src={require("../../assets/images/landingPage2V2/bhatBalls.png")}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className="howToPlaySection">
        <Container>
          <Typography className="secondaryTitle text-center">
            ThaiLottery in 3 Steps
          </Typography>
          <h2 className="mainTitle text-center">How to Play the Thailottery</h2>
          <Typography className="commonText text-center stepDescription">
            To play the Thailand Lottery, buy a 6-digit ticket and wait for the
            draw on the 1st or 16th. If your number matches the winning one, you
            win a prize. Here's how to get started:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">01</span>Register
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt="BetBhat"
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/register.svg")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">02</span>Play Game
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt="BetBhat"
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/play.svg")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="playType">
                <h3 className="mainTitle">
                  <span className="count">03</span>Win Lottery
                </h3>
                <Box className="playTypeBody">
                  <img
                    alt="BetBhat"
                    className="playTypeImage"
                    src={require("../../assets/images/landingPage2V2/win.svg")}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="acceptedPaymentsSection boxSpacing">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <div className="scroll-container">
                <div className="scroll-content">
                  <Box className="wh100">
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 2.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 5.png")}
                        />
                      </Box>
                    </Box>
                    <Box className="mainPart">
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 4.png")}
                        />
                      </Box>
                      <Box className="imgPart">
                        <img
                          alt="BetBhat"
                          className="paymentType"
                          src={require("../../assets/images/landingPage2V2/Frame 3.png")}
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box className="">
                <h2 className="mainTitle">
                  Accepted <br /> Payment Methods
                </h2>
                <Box>
                  <Typography className="secondaryTitle">
                    Cards and Digital Wallets
                  </Typography>
                  <Typography className="commonText">
                    Secure options like Visa, MasterCard, and JCB are widely
                    accepted, while PayPal, Apple Pay, Google Pay, and Samsung
                    Pay offer fast, convenient payments for lottery
                    participants.
                  </Typography>
                </Box>
                <Box>
                  <Typography className="secondaryTitle">
                    Bank Transfers
                  </Typography>
                  <Typography className="commonText">
                    Direct bank transfers and eChecks are trusted methods, ideal
                    for larger ticket purchases or claiming winnings, ensuring
                    secure and smooth transactions.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="socialMediaSection boxSpacing">
        <Box className="socialBg">
          <Box className="contentCenter">
            <h2>If You are interested in Thai lottery Online</h2>
            <Button variant="contained" className="referEarn">
              Refer Friends, Earn Money
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className="footerSection">
        <Box className="footerSectionTop">
          <Box>
            <img
              alt="BetBhat"
              className="footerLogo"
              src={require("../../assets/images/landingPage2V2/logoNew.png")}
            />
          </Box>
          <Typography className="commonText footerInfo">
            The Thailand Lottery is a government-regulated lottery held twice a
            month, offering cash prizes, including a grand prize. Proceeds
            support social welfare programs in the country.
          </Typography>
          <Box className="footerLinksPart">
            <Link href="#" className="linkNav">
              Agent Registration
            </Link>
            <Link href="#" className="linkNav">
              Rules & Conditions
            </Link>
            <Link href="#" className="linkNav">
              Exchange Rates
            </Link>
            <Link href="#" className="linkNav">
              All Lottery Game Results
            </Link>
          </Box>
          <Box>
            <img
              alt="Play Store"
              className="btnRightSpacing"
              src={require("../../assets/images/landingPage2V2/PlayStoreIcon.png")}
            />
            <img
              alt="Play Store"
              className=""
              src={require("../../assets/images/landingPage2V2/AppStoreIcon.png")}
            />
          </Box>
        </Box>
        <Box className="footerSectionBottom">
          <Typography>© 2024 Betbhat.com,All Rights Reserved.</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default LandingPageV2;

import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

import AuthService from "../../../src/services/offers/offers.service";
import { useNavigate } from "react-router-dom";
import { IApiResponse } from "../../../src/interface/Response/IApiResponse";
import { ToastContext } from "../../../src/context/toast.context";
import { Formik } from "formik";
import { IAlertDialogConfig } from "../../../src/interface/IAlertDialogConfig";

//Links
import Link from "@mui/material/Link";

//CSS
import "../offers/offers.screen.style.css";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import AlertDialog from "../../components/alertDialog/alertDialog.component";
import * as yup from "yup";
import GamesConfigService from "../../services/gamesConfig/gamesConfig.service";
import LookupService from "../../services/lookup/lookup.service";
import {
  API_ERROR_STANDARD_MESSAGE,
  DEFAULT_ALERT_CONFIG,
  OFFER_TYPE,
  OfferType,
  RoleType,
  SUPPORTED_FORMATS,
} from "../../constants/DBConstants.contant";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import dayjs, { Dayjs } from "dayjs";
import OfferService from "../../../src/services/offers/offers.service";
import Dialog from "@mui/material/Dialog";
import { IOffersModel } from "../../interface/BusinessModels/IOffersModel";
import ApplicationHelperService from "../../services/ApplicationHelperService";
import { useSelector } from "react-redux";
import { LoadingContext } from "../../context/loading.context";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import IconButton from "@mui/material/IconButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
//Modal
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Offers: FunctionComponent<PageProps> = ({ title }) => {
  const [alertConfig, setAlertConfig] = useState<IAlertDialogConfig>({
    isShow: false,
    callBack: () => undefined,
  });
  const _applicationHelperService = new ApplicationHelperService({});
  const _authService = new AuthService({});
  const navigate = useNavigate();
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const _gamesConfigService = new GamesConfigService({});
  const _lookupService = new LookupService({});
  const _offerService = new OfferService({});
  const [offerTypes, setOfferTypes] = useState<ILookupModel[]>([]);
  const [offers, setOffers] = useState<IOffersModel[]>([]);
  const [showReviewDialog, setShowReviewDialog] = useState<any>({
    isShow: false,
    titleText: "",
    descriptionText: "",
    formValues: null,
  });
  const [currentDate] = useState<Date>(new Date());
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const { user } = userInfo;
  const { setLoading } = useContext(LoadingContext);
  const [activeDeleteId, setActiveDeleteId] = useState<number>(0);

  useEffect(() => {
    getOffer();
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const getOffer = async () => {
    try {
      setLoading(true);
      await getOfferTypes();
      const offerResponse: IApiResponse = await _offerService.getOffers();
      setOffers(offerResponse.response as IOffersModel[]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  const getOfferTypes = async () => {
    try {
      const offerTypeResponse: IApiResponse = await _lookupService.getConfigs(
        OFFER_TYPE
      );
      setOfferTypes(offerTypeResponse.response as ILookupModel[]);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  const InitialValues = {
    offerType: "",
    title: "",
    description: "",
    minAmount: "",
    bonusAmount: 0,
    bonusPercentage: 0,
    validFrom: Dayjs,
    validTill: Dayjs,
    file: "",
  };

  const OffersSchema = yup.object().shape({
    offerType: yup.string().required("Offer Type is required."),
    title: yup.string().required("Title is required."),
    description: yup.string().required("Description is required."),
    minAmount: yup.number().required("Min Amount is required."),
    bonusAmount: yup.number(),
    bonusPercentage: yup.number().max(100, "Should be lessthan 100"),
    validFrom: yup.string().required("Valid From is required."),
    validTill: yup.string().required("Valid Till is required."),
    file: yup
      .mixed()
      .required("Logo File is required.")
      .test(
        "The file is too large",
        "The file is too large",
        (value: any) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "Only the following formats are accepted: .jpeg, .jpg, .png",
        "Only the following formats are accepted: .jpeg, .jpg, .png",
        (value: any) =>
          !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  const _getTitleDescription = (values: any) => {
    let titleText: string = "";
    let descriptionText: string = "";
    titleText = (values.title as string).replace(
      "{{MinAmount}}",
      values.minAmount
    );
    descriptionText = (values.description as string).replace(
      "{{MinAmount}}",
      values.minAmount
    );
    if (values.title === "Referral Bonus") {
      if (values.bonusAmount > 0 && values.bonusPercentage === 0) {
        titleText = titleText.replace(
          "{{TotalValue}}",
          values.bonusAmount.toString()
        );
        descriptionText = descriptionText.replace(
          "{{TotalValue}}",
          values.bonusAmount.toString()
        );
      }
      if (values.bonusAmount === 0 && values.bonusPercentage > 0) {
        titleText = titleText.replace(
          "{{TotalValue}}",
          (+values.minAmount).toString()
        );
        descriptionText = descriptionText.replace(
          "{{TotalValue}}",
          `${values.bonusPercentage}%`
        );
      }
    } else {
      if (values.bonusAmount > 0 && values.bonusPercentage === 0) {
        titleText = titleText.replace(
          "{{TotalValue}}",
          (+values.minAmount + +values.bonusAmount).toString()
        );
        descriptionText = descriptionText.replace(
          "{{TotalValue}}",
          (+values.minAmount + +values.bonusAmount).toString()
        );
      }
      if (values.bonusAmount === 0 && values.bonusPercentage > 0) {
        titleText = titleText.replace(
          "{{TotalValue}}",
          (+values.minAmount + +values.bonusAmount).toString()
        );
        descriptionText = descriptionText.replace(
          "{{TotalValue}}",
          `${values.bonusPercentage}%`
        );
      }
    }

    return { titleText, descriptionText };
  };

  const _handleForOffersPage = async (values: any) => {
    try {
      const formData = new FormData();
      Object.keys(values.formValues).forEach((key) => {
        if (key === "title") {
          formData.append(key, values.titleText);
        } else if (key === "description") {
          formData.append(key, values.descriptionText);
        } else {
          formData.append(key, values.formValues[key]);
        }
      });
      var response: IApiResponse = await _offerService.saveOffer(formData);
      setShowReviewDialog({
        isShow: false,
        titleText: "",
        descriptionText: "",
        formValues: null,
      });
      if (!response.isSuccess) {
        setTimeout(() => {
          setToastConfig(ToastSeverity.Error, response.message, true);
        }, 1000);
      } else {
        setTimeout(() => {
          setAlertConfig({
            description: response.response.message,
            toastSeverity: ToastSeverity.Success,
            isShow: true,
            callBack: () => {
              setAlertConfig(DEFAULT_ALERT_CONFIG);
              navigate("/dashboard");
            },
          });
        }, 1000);
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  const _reviewOffersPage = async (values: any) => {
    try {
      const isValid = await OffersSchema.isValid(values);
      if (isValid) {
        const tileDesc = _getTitleDescription(values);
        setShowReviewDialog({
          isShow: true,
          formValues: values,
          titleText: tileDesc.titleText,
          descriptionText: tileDesc.descriptionText,
        });
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
      console.log(error);
    }
  };

  const deleteOffer = async () => {
    try {
      var response: IApiResponse = await _offerService.deleteOffer(
        activeDeleteId
      );
      if (response.isSuccess) {
        setActiveDeleteId(0);
        setAlertConfig({
          description: response.response.message,
          toastSeverity: ToastSeverity.Success,
          isShow: true,
          callBack: () => {
            setAlertConfig(DEFAULT_ALERT_CONFIG);
            navigate("/offers");
          },
        });
      }
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  return (
    <Box className="pageMain offersPage">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          {/* <Box className="pageViewHead">
                        <Typography variant="h6">Offers</Typography>
                    </Box> */}
          <Box className="pageViewBody commonScroll dse">
            <Box>
              <Grid container spacing={1}>
                {offers &&
                  offers.map((item: IOffersModel) => (
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={4}
                      xl={3}
                      key={item.id}
                    >
                      <Box className="commonCard posRelative">
                        <Card>
                          <CardContent>
                            <Box className="commonBgCard offerImage">
                              <img
                                alt="BetBhat - Offers"
                                className="commonImage"
                                src={item.offerLogo}
                              />
                            </Box>
                            <Box>
                              <Typography className="sectionTitle textEclipse">
                                {item.title}
                              </Typography>
                              <Typography className="normalText">
                                {item.description}
                              </Typography>
                              <Typography className="normalText">
                                Valid till{" "}
                                {_applicationHelperService.getExpandedDateTimeFormat(
                                  item.validTill
                                )}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                        {[RoleType.Admin, RoleType.SuperAdmin].includes(
                          user.roleId
                        ) && (
                          <Box className="cardActionBtn">
                            <Box>
                              <IconButton
                                aria-label="edit"
                                className="primaryOverlayIconBtn"
                                onClick={() => setActiveDeleteId(item.id)}
                              >
                                <DeleteRoundedIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  ))}
              </Grid>

              {[RoleType.Admin, RoleType.SuperAdmin].includes(user.roleId) && (
                <Formik
                  initialValues={{ ...InitialValues }}
                  validationSchema={OffersSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    _reviewOffersPage(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <Box className="saperationSection">
                      <Box className="commonCard">
                        <Card>
                          <CardContent>
                            <form onSubmit={handleSubmit} className="height100">
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={3}>
                                  <FormControl
                                    className="commonInputTopSpacing"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <InputLabel id="outlined-country-dropdown-label">
                                      Offer Type
                                    </InputLabel>
                                    <Select
                                      labelId="offerType"
                                      id="offerType"
                                      label="Offer Type"
                                      value={values.offerType}
                                      onChange={(evt: SelectChangeEvent) => {
                                        setFieldValue(
                                          "offerType",
                                          evt.target.value
                                        );
                                        switch (+evt.target.value) {
                                          case OfferType.WELCOME_BONUS:
                                            setFieldValue(
                                              "title",
                                              "Welcome Bonus Offer"
                                            );
                                            setFieldValue(
                                              "description",
                                              "Minumum recharge of {{MinAmount}} and get {{TotalValue}} as bonus."
                                            );
                                            break;
                                          case OfferType.RECHARGE_BONUS:
                                            setFieldValue(
                                              "title",
                                              "Recharge Bonus"
                                            );
                                            setFieldValue(
                                              "description",
                                              "Recharge with minimum amount of {{MinAmount}} or more and get {{TotalValue}} as bonus."
                                            );
                                            break;
                                          case OfferType.REFERRAL_BONUS:
                                            setFieldValue(
                                              "title",
                                              "Referral Bonus"
                                            );
                                            setFieldValue(
                                              "description",
                                              "Refer a friend and Get {{TotalValue}} on referrals mininum recharge of {{MinAmount}}"
                                            );
                                            break;
                                        }
                                      }}
                                      fullWidth
                                    >
                                      {offerTypes &&
                                        offerTypes.map(
                                          (option: ILookupModel) => (
                                            <MenuItem
                                              key={option.id}
                                              value={option.id.toString()}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                    {errors.offerType && touched.offerType && (
                                      <FormHelperText className="errorMessage">
                                        {errors.offerType}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <TextField
                                    id="title"
                                    type="text"
                                    label="Title"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    onChange={handleChange}
                                    value={values.title}
                                  />
                                  {errors.title && touched.title && (
                                    <FormHelperText className="errorMessage">
                                      {errors.title}
                                    </FormHelperText>
                                  )}
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                  <TextField
                                    id="minAmount"
                                    type="number"
                                    label="Min Amount"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    onChange={handleChange}
                                    value={values.minAmount}
                                  />
                                  {errors.minAmount && touched.minAmount && (
                                    <FormHelperText className="errorMessage">
                                      {errors.minAmount}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <TextField
                                    id="bonusAmount"
                                    type="number"
                                    label="Bonus Amount"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    onChange={(evt) => {
                                      setFieldValue(
                                        "bonusAmount",
                                        evt.target.value
                                      );
                                      setFieldValue("bonusPercentage", 0);
                                    }}
                                    value={values.bonusAmount}
                                  />
                                  {errors.bonusAmount &&
                                    touched.bonusAmount && (
                                      <FormHelperText className="errorMessage">
                                        {errors.bonusAmount}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <TextField
                                    id="bonusPercentage"
                                    type="number"
                                    label="Bonus Percentage"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    onChange={(evt) => {
                                      setFieldValue("bonusAmount", 0);
                                      setFieldValue(
                                        "bonusPercentage",
                                        evt.target.value
                                      );
                                    }}
                                    value={values.bonusPercentage}
                                  />
                                  {errors.bonusPercentage &&
                                    touched.bonusPercentage && (
                                      <FormHelperText className="errorMessage">
                                        {errors.bonusPercentage}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      label="Valid From"
                                      className="commonDatePicker commonInputTopSpacing"
                                      format="DD-MMM-YYYY"
                                      onChange={(newValue: any) => {
                                        setFieldValue(
                                          "validFrom",
                                          dayjs(newValue).format("DD-MMM-YYYY")
                                        );
                                      }}
                                      value={values.validFrom}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      label="Valid To"
                                      className="commonDatePicker commonInputTopSpacing"
                                      format="DD-MMM-YYYY"
                                      onChange={(newValue: any) => {
                                        setFieldValue(
                                          "validTill",
                                          dayjs(newValue).format("DD-MMM-YYYY")
                                        );
                                      }}
                                      value={values.validTill}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                  >
                                    {/* <InputLabel htmlFor="file">Offer Logo</InputLabel> */}
                                    <OutlinedInput
                                      id="file"
                                      type="file"
                                      label="Offer Logo"
                                      onChange={(event: any) => {
                                        setFieldValue(
                                          "file",
                                          event.currentTarget.files[0]
                                        );
                                      }}
                                    />
                                  </FormControl>
                                  {errors.file && (
                                    <FormHelperText className="errorMessage">
                                      {errors.file}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                  <TextField
                                    id="description"
                                    type="text"
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    onChange={handleChange}
                                    value={values.description}
                                  />
                                  {errors.description &&
                                    touched.description && (
                                      <FormHelperText className="errorMessage">
                                        {errors.description}
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>
                              <Box className="floatRightBtn">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="primaryFillBtn width100"
                                >
                                  <span>Review</span>
                                </Button>
                              </Box>

                              <Dialog
                                className="commonModal"
                                open={showReviewDialog.isShow}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                {showReviewDialog.isShow && (
                                  <Grid item xs={12} md={6} lg={4}>
                                    <Box className="commonCard">
                                      <Card>
                                        <CardContent>
                                          <Box className="commonBgCard offerImage">
                                            <img
                                              alt="BetBhat - Offers"
                                              className="commonImage"
                                              src={URL.createObjectURL(
                                                showReviewDialog.formValues.file
                                              )}
                                            />
                                          </Box>
                                          <Box>
                                            <Typography className="sectionTitle textEclipse w">
                                              {showReviewDialog.titleText}
                                            </Typography>
                                            <Typography className="normalText">
                                              {showReviewDialog.descriptionText}
                                            </Typography>
                                          </Box>
                                        </CardContent>
                                        <CardActions className="floatRightBtn">
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            className="primaryFillBtn width100"
                                            onClick={() =>
                                              _handleForOffersPage(
                                                showReviewDialog
                                              )
                                            }
                                          >
                                            <span>Submit</span>
                                          </Button>
                                          <Button
                                            type="button"
                                            variant="outlined"
                                            className="primaryFillBtn width100"
                                            onClick={() =>
                                              setShowReviewDialog({
                                                isShow: false,
                                                titleText: "",
                                                descriptionText: "",
                                                formValues: null,
                                              })
                                            }
                                          >
                                            <span>Cancel</span>
                                          </Button>
                                        </CardActions>
                                      </Card>
                                    </Box>
                                  </Grid>
                                )}
                              </Dialog>
                            </form>
                          </CardContent>
                        </Card>
                      </Box>
                    </Box>
                  )}
                </Formik>
              )}
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
          {alertConfig && alertConfig.isShow && (
            <AlertDialog
              alertConfig={alertConfig}
              callBack={alertConfig.callBack}
            />
          )}
        </Box>
        {activeDeleteId > 0 && (
          <Modal
            className="commonModal"
            open={activeDeleteId > 0}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="commonModalBox">
              <Box className="commonModalBoxInner">
                <Box id="modal-modal-title" className="commonModalHead">
                  <Grid container spacing={1}>
                    <Grid item xs={11} md={11} lg={11}>
                      <Typography className="modalTitle">
                        Delete Offer
                      </Typography>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}>
                      <span className="modalClose">
                        <CloseRoundedIcon
                          onClick={() => setActiveDeleteId(0)}
                        />
                      </span>
                    </Grid>
                  </Grid>
                </Box>
                <Box id="modal-modal-description" className="commonModalBody">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography>
                        Are you sure you want to delete the offer. Deleting the
                        offer will not revert the past transactions?
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box id="modal-modal-footer" className="commonFooter">
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      className="primaryLineBtn"
                      onClick={() => setActiveDeleteId(0)}
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button
                      variant="contained"
                      className="primaryFillBtn"
                      onClick={() => deleteOffer()}
                    >
                      <span>Delete</span>
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
      </Box>
    </Box>
  );
};
export default Offers;

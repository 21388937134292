import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Css
import "../myWallet/myWallet.screen.style.css";
import { ITransactionsRequest } from "../../interface/Request/ITransactionsRequest";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import TransactionService from "../../services/transaction/transaction.service";
import { ILotteryHistoryResponseModel } from "../../interface/Response/ILotteryHistoryResponseModel";
import { ITransactionsResponse } from "../../interface/Response/ITransactionsResponse";
import Pagination from "@mui/material/Pagination";
import { ToastContext } from "../../context/toast.context";

//Icons Import
import ConfirmationNumberRoundedIcon from "@mui/icons-material/ConfirmationNumberRounded";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { API_ERROR_STANDARD_MESSAGE } from "../../constants/DBConstants.contant";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { UUID } from "crypto";
import { useLocation } from "react-router-dom";

const columns: GridColDef[] = [
  { field: "description", headerName: "Description", width: 60 },
  { field: "createdOn", headerName: "Created On", width: 25 },
  { field: "amount", headerName: "Amount", width: 15 },
];

const MyWallet: FunctionComponent<PageProps> = ({ title }) => {
  const location = useLocation();
  const _transactionService = new TransactionService({});
  const [transactionRequest, setTransactionRequest] = useState<
    ITransactionsRequest
  >({
    transactionType: "",
    PageNumber: 1,
    PageSize: 10,
  });
  const [transactionResponse, setTransactionResponse] = useState<
    ITransactionsResponse
  >({
    transactions: [],
    pagination: {
      totalPages: 0,
      totalRecords: 0,
      pageNumber: 0,
      pageSize: 0,
    },
  });
  const { setToastConfig, setOpen } = useContext(ToastContext);
  const [userId] = useState<UUID>(location.state as UUID);
  useEffect(() => {
    document.title = title;
  }, []);

  const getTransaction = async () => {
    try {
      let response;
      if (userId) {
        response = await _transactionService.userTransactionHistory(
          userId,
          transactionRequest
        );
      } else {
        response = await _transactionService.transactionHistory(
          transactionRequest
        );
      }
      setTransactionResponse(response.response as ITransactionsResponse);
    } catch (error) {
      setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    }
  };

  useEffect(() => {
    getTransaction();
  }, [transactionRequest, userId]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setTransactionRequest({
      ...transactionRequest,
      PageNumber: value,
    });
  };

  function Row(props: { row: any; rowNumber: number }) {
    const { row, rowNumber } = props;
    return (
      <React.Fragment>
        <TableRow key={rowNumber} sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell key={0} data-label="S.No">
            {rowNumber}
          </TableCell>
          <TableCell key={1} data-label="Description">
            {row.description}
          </TableCell>
          <TableCell key={2} data-label="Created On">
            {row.createdOn}
          </TableCell>
          <TableCell key={3} data-label="Amount">
            {row.amount}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Box className="pageMain myWalletPage">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody commonScroll">
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} className="walletBox">
                  <Typography className="amountTitle whiteColor">
                    Available Balance
                  </Typography>
                  <Box>
                    <Typography className="amount whiteColor">
                      {" "}
                      {transactionResponse &&
                        transactionResponse.walletBalance &&
                        transactionResponse.walletBalance.balance}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
                  <Box className="bannerImage">
                    <img
                      alt="BetBhat - Wallet"
                      className=""
                      src={require("../../assets/images/myWallet/walletAmount.png")}
                    />
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
            <Box className="saperationSection" sx={{ display: "none" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className="commonCard textDecorationNone">
                    <Card>
                      <CardContent>
                        <Box className="commonBgCard saperatorSpacing">
                          <Grid container spacing={2}>
                            <Grid item xs={10} md={10} lg={10}>
                              <Typography className="cardTitle">
                                TN2024051800000007
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              md={2}
                              lg={2}
                              className="boxRight padL0"
                            >
                              <Box className="verificationStatus verified">
                                <ConfirmationNumberRoundedIcon />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Created On
                          </Typography>
                          <Typography className="commonValue">
                            2024-05-18T09:34:50.287
                          </Typography>
                        </Box>
                        <Box className="commonLabelValue">
                          <Typography className="commonLabel">
                            Amount
                          </Typography>
                          <Typography className="commonValue">200</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              {transactionResponse.transactions.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    {/* <DataGrid
                      getRowId={(row) => row.transactionId}
                      rows={transactionResponse.transactions}
                      columns={columns}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      pagination
                      paginationMode="server"
                      rowCount={transactionResponse.pagination.totalRecords}
                      onPaginationModelChange={(model: GridPaginationModel) => {
                        setTransactionRequest({
                          ...transactionRequest,
                          PageNumber: model.page + 1,
                        });
                      }}
                      pageSizeOptions={[25, 50]}
                      paginationModel={{
                        page: transactionResponse.pagination.pageNumber - 1,
                        pageSize: transactionResponse.pagination.pageSize,
                      }}
                    /> */}
                    <TableContainer
                      component={Paper}
                      className="commonTable responsiveTable"
                    >
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              width={60}
                              key={0}
                              variant="head"
                              // style={{ width: column.width }}
                              sx={{
                                backgroundColor: "background.paper",
                              }}
                            >
                              {"S.No"}
                            </TableCell>
                            {columns.map((column, index) => (
                              <TableCell
                                width={`${column.width}%`}
                                key={index + 1}
                                variant="head"
                                // style={{ width: column.width }}
                                sx={{
                                  backgroundColor: "background.paper",
                                }}
                              >
                                {column.headerName}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactionResponse.transactions.map(
                            (row, index) => (
                              <Row
                                row={row}
                                rowNumber={
                                  (transactionResponse.pagination.pageNumber -
                                    1) *
                                    transactionResponse.pagination.pageSize +
                                  (index + 1)
                                }
                              />
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={transactionResponse.pagination.totalRecords}
                      rowsPerPage={transactionResponse.pagination.pageSize}
                      page={transactionResponse.pagination.pageNumber - 1}
                      onPageChange={(event, page) => {
                        setTransactionRequest({
                          ...transactionRequest,
                          PageNumber: page + 1,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default MyWallet;

import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { isReturnStatement } from "typescript";
import PageProps from "../../models/PageProps.interface";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

//Component Import
import LeftMenu from "../../components/leftMenu/leftMenu.component";
import TopNav from "../../components/topNav/topNav.component";
import Footer from "../../components/footer/footer.component";

//Icons
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import AddLinkRoundedIcon from "@mui/icons-material/AddLinkRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

//Css
import "../dashboard/dashboard.screen.style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import UserService from "../../services/user/user.service";
import { IWalletBalanceModel } from "../../interface/BusinessModels/IWalletBalanceModel";
import { LoadingContext } from "../../context/loading.context";
import LookupService from "../../services/lookup/lookup.service";
import {
  API_ERROR_STANDARD_MESSAGE,
  SOCIAL_CONNECTION_SETTINGS,
} from "../../constants/DBConstants.contant";
import { ILookupModel } from "../../interface/BusinessModels/ILookupModel";
import { ToastContext } from "../../context/toast.context";
import { ToastSeverity } from "../../constants/toastSeverity.contants";
import { AppConfig } from "../../constants/AppConfig.constant";
import { getWalletBalance } from "../../actions/lookup.actions";

const Dashboard: FunctionComponent<PageProps> = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const { walletBalance } = useSelector((state: any) => state.lookupReducer);
  const getUserWalletBalance = () => dispatch<any>(getWalletBalance());
  const { user } = userInfo;
  const _userService = new UserService({});
  const _lookupService = new LookupService({});
  const [referralCount, setReferralCount] = useState<number>(0);
  const [whatsappLink, setWhatsappLink] = useState<string>("");
  const { setLoading } = useContext(LoadingContext);
  const { setToastConfig, setOpen } = useContext(ToastContext);

  useEffect(() => {
    getUserWalletBalance();
    getReferralsCount();
    getSocialConnectionSettings();

    // async function getWalletBalance() {
    //   try {
    //     setLoading(true);
    //     const response: IApiResponse = await _userService.walletBalance();
    //     setWalletBalance(response.response as IWalletBalanceModel);
    //   } catch (error) {
    //     setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
    //     setLoading(false);
    //   }
    // }

    async function getReferralsCount() {
      try {
        const response: IApiResponse = await _userService.refferalCount();
        setReferralCount(+response.response);
      } catch (error) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        setLoading(false);
      }
    }

    async function getSocialConnectionSettings() {
      try {
        const socialConnectionSettings: IApiResponse = await _lookupService.getGameSettings(
          SOCIAL_CONNECTION_SETTINGS,
          0
        );

        const whatsLink = socialConnectionSettings.response.filter(
          (x: ILookupModel) => x.key === "WHATSAPP_LINK"
        )[0].value;
        if (whatsLink !== "") {
          setWhatsappLink(whatsLink);
        }
        setLoading(false);
      } catch (error) {
        setToastConfig(ToastSeverity.Error, API_ERROR_STANDARD_MESSAGE, true);
        setLoading(false);
      }
    }
  }, []);

  return (
    <Box className="pageMain">
      <Box className="pageLeft">
        <LeftMenu />
      </Box>
      <Box className="pageRight">
        <Box className="pageHead">
          <TopNav title={title.split("|")[1].trim()} />
        </Box>
        <Box className="pageView">
          <Box className="pageViewBody pageDashboard commonScroll">
            <Box className="commonBgCard saperatorSpacing">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={9}
                  className="dashboardBannerContent"
                >
                  <Box className="saperatorSpacing">
                    <Typography className="dashboardBannerTitle whiteColor">
                      Hello{" "}
                      <span className="whiteColor">
                        {user.firstname ? user.firstname : user.appId}
                      </span>
                    </Typography>
                    <Typography className="normalText whiteColor">
                      Welcome to {AppConfig.applicationName}
                    </Typography>
                  </Box>
                  <Box>
                    <Stack spacing={2} direction="row">
                      <Typography className="saperatorSpacing">
                        <span className="floatL whiteColor marR8">
                          <AddLinkRoundedIcon />
                        </span>
                        <span>
                          <a
                            href={
                              window.location.origin +
                              "/signup?ref=" +
                              user.referralCode
                            }
                            className="normalText whiteColor"
                          >
                            {window.location.origin}/signup?ref=
                            {user.referralCode}
                          </a>
                        </span>
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  className="responsiveHiddenPart"
                >
                  <Box className="bannerImage">
                    <img
                      alt="BetBhat - Wallet"
                      className=""
                      src={require("../../assets/images/dashboard/user.png")}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={4} xl={4}>
                  <Box className="saperatorSpacing">
                    <Card className="appIdCard dashboardCards">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                User Id
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {user.appId}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            lg={2}
                            className="responsiveHiddenPart"
                          >
                            <Box>
                              <AccountCircleRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6} lg={4} xl={4}>
                  <Box className="saperatorSpacing">
                    <Card className="referralCard dashboardCards">
                      <CardContent>
                        <Grid
                          style={{ cursor: "pointer" }}
                          container
                          spacing={2}
                          onClick={() => navigate("/referralList")}
                        >
                          <Grid item xs={12} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Referrals
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {referralCount}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            lg={2}
                            className="responsiveHiddenPart"
                          >
                            <Box>
                              <PeopleAltRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={4}
                  className="responsiveHiddenPart"
                >
                  <Box className="saperatorSpacing">
                    <Card className="balanceCard dashboardCards">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={10} md={10} lg={10}>
                            <Box>
                              <Typography className="dashboardCardTitle">
                                Balance
                              </Typography>
                              <Typography className="dashboardCardValue">
                                {walletBalance.balance}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <Box>
                              <AccountBalanceWalletRoundedIcon className="dashboardCardIcon" />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {whatsappLink != null && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className="commonCard">
                      <Card>
                        <CardContent className="padB0">
                          <Typography className="sectionTitle">
                            How It Works?
                          </Typography>
                          <Box>
                            <ol className="commonUL normalText">
                              <li>
                                Share your referral code with friends and ask
                                them to register now.
                              </li>
                              <li>
                                Receive A referral Bonus when your friends play.
                              </li>
                              <li>
                                Use Below Social Media Platforms to invite
                                friends.
                              </li>
                            </ol>
                          </Box>
                        </CardContent>
                        <CardActions>
                          <Link
                            href={whatsappLink}
                            className="socialMediaBtn whatsapp"
                            target="_blank"
                          >
                            <span className="floatL btnIcon">WhatsApp</span>{" "}
                            <span className="socialMediaIcon">
                              <img
                                alt="BetBhat - Whatsapp"
                                className=""
                                src={require("../../assets/images/dashboard/whatsapp.png")}
                              />
                              {/* <WhatsAppIcon /> */}
                            </span>
                          </Link>
                          <Link
                            href={whatsappLink}
                            className="socialMediaBtn wechat"
                            target="_blank"
                          >
                            <span className="floatL btnIcon">WeChat</span>{" "}
                            <span className="socialMediaIcon">
                              <img
                                alt="BetBhat - WeChat"
                                className=""
                                src={require("../../assets/images/dashboard/wechat.png")}
                              />
                              {/* <WhatsAppIcon /> */}
                            </span>
                          </Link>
                          <Link
                            href={whatsappLink}
                            className="socialMediaBtn viber"
                            target="_blank"
                          >
                            <span className="floatL btnIcon">Viber</span>{" "}
                            <span className="socialMediaIcon">
                              <img
                                alt="BetBhat - Viber"
                                className=""
                                src={require("../../assets/images/dashboard/viber.png")}
                              />
                              {/* <WhatsAppIcon /> */}
                            </span>
                          </Link>
                          <Link
                            href={whatsappLink}
                            className="socialMediaBtn facebook"
                            target="_blank"
                          >
                            <span className="floatL btnIcon">Facebook</span>{" "}
                            <span className="socialMediaIcon">
                              <img
                                alt="BetBhat - Facebook"
                                className=""
                                src={require("../../assets/images/dashboard/facebook.png")}
                              />
                              {/* <WhatsAppIcon /> */}
                            </span>
                          </Link>
                          <Link
                            href={whatsappLink}
                            className="socialMediaBtn instagram"
                            target="_blank"
                          >
                            <span className="floatL btnIcon">Instagram</span>{" "}
                            <span className="socialMediaIcon">
                              <img
                                alt="BetBhat - Instagram"
                                className=""
                                src={require("../../assets/images/dashboard/instagram.png")}
                              />
                              {/* <WhatsAppIcon /> */}
                            </span>
                          </Link>
                          <Link
                            href={whatsappLink}
                            className="socialMediaBtn imo"
                            target="_blank"
                          >
                            <span className="floatL btnIcon">IMO</span>{" "}
                            <span className="socialMediaIcon">
                              <img
                                alt="BetBhat - IMO"
                                className=""
                                src={require("../../assets/images/dashboard/imo.png")}
                              />
                              {/* <WhatsAppIcon /> */}
                            </span>
                          </Link>
                        </CardActions>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;

import React, { FunctionComponent, useEffect, useState } from "react";
import PageProps from "../../models/PageProps.interface";

//Gadgets
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Marquee from "react-fast-marquee";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import YouTube from "react-youtube";

//Gadgest - List
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

//Table Import
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

//Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from "react-router-dom";
//Import Css
import "../rules/rules.screen.style.css";

//Component Import
import TopNav from "../../components/topNav/topNav.component";
import MainFooter from "../../components/mainFooter/mainFooter.component";
import Footer from "../../components/footer/footer.component";

import "../landingPage/landingPage.screen.style.css";
import PlayGameService from "../../services/playGame/playGame.service";
import { IApiResponse } from "../../interface/Response/IApiResponse";
import { IHomepageGameDataResponseModel } from "../../interface/Response/IHomepageGameDataResponseModel";
import { AppConfig } from "../../constants/AppConfig.constant";
import {
  GameDataWinningsDiscount,
  GameDetailChart,
  IHomepageGameRuleDataResponseModel,
} from "../../interface/Response/IHomepageGameRuleDataResponseModel";

const Rules: FunctionComponent<PageProps> = ({ title }) => {
  const [homeGameRuleData, setHomeGameRuleDate] = useState<
    IHomepageGameRuleDataResponseModel
  >();

  // YouTube video ID
  const videoId = "vH864uS3Juk";
  const navigate = useNavigate();

  const _playGameService = new PlayGameService({});

  useEffect(() => {
    getGameData();
    async function getGameData() {
      var response: IApiResponse = await _playGameService.getHomeGameRuleData();
      var homepageRules = response.response as IHomepageGameRuleDataResponseModel;
      setHomeGameRuleDate(homepageRules);
    }
  }, []);

  return (
    <Box className="pageMain landingPage">
      <Box className="">
        <Box className="landingPageNavBar">
          <Container className="customContainer">
            <Grid container spacing={1}>
              <Grid item xs={2} md={2} lg={2} className="">
                <Box className="HeaderLogo">
                  <img
                    alt="BetBhat - Brand Logo"
                    className="commonImage"
                    src={require("../../assets/images/common/logo.png")}
                    onClick={() => navigate("/")}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={10}
                md={10}
                lg={10}
                className="singlePageLayoutNavbarActions"
              >
                <TopNav title={title.split("|")[1].trim()} />
              </Grid>
            </Grid>
          </Container>
          <Container style={{ display: "none" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} lg={4} className="">
                <Box className="HeaderLogo">
                  <img
                    alt="BetBhat - Brand Logo"
                    className="commonImage"
                    src={require("../../assets/images/common/logo.png")}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={9} lg={8}>
                {/* <GoogleTranslate /> */}
                <Stack spacing={2} direction="row" className="floatR">
                  <Button
                    type="submit"
                    variant="contained"
                    className="primaryFillBtn"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate("/login");
                    }}
                  >
                    <span>Login</span>
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    className="primaryLineBtn"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate("/signup");
                    }}
                  >
                    <span className="whiteColor">Signup</span>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className="pageViewBody rulesPage">
          <Container>
            <Typography className="pageTitle">Rules & Conditions</Typography>
            {homeGameRuleData && (
              <Box>
                <Box className="commonRules saperationSection">
                  <Box className="sectionTitle">Application Rules</Box>
                  <Typography>
                    <span className="bulletPoint"></span>One man One account &
                    should be verified.
                  </Typography>
                  <Typography>
                    <span className="bulletPoint"></span>The minimum recharge
                    amount is{" "}
                    <span className="ruleValue">
                      {homeGameRuleData.minimumRechargeAmount}
                    </span>
                    .
                  </Typography>
                  <Typography>
                    <span className="bulletPoint"></span>The maximum recharge
                    amount is{" "}
                    <span className="ruleValue">
                      {homeGameRuleData.maximumRechargeAmount}
                    </span>
                    .
                  </Typography>
                  <Typography>
                    <span className="bulletPoint"></span>The minimum withdraw
                    amount is{" "}
                    <span className="ruleValue">
                      {homeGameRuleData.minimumWithdrawAmount}
                    </span>
                    .
                  </Typography>
                  <Typography>
                    <span className="bulletPoint"></span>The maximum amount you
                    can withdraw is{" "}
                    <span className="ruleValue">
                      {homeGameRuleData.maximumWithdrawAmount}
                    </span>
                    .
                  </Typography>
                  <Typography>
                    <span className="bulletPoint"></span>Transaction processing
                    fee is{" "}
                    <span className="ruleValue">
                      {homeGameRuleData.transactionProcessingFee}%
                    </span>
                    .
                  </Typography>
                </Box>

                {homeGameRuleData.gameDetailCharts.map((x: GameDetailChart) => {
                  return (
                    <Box>
                      <Box className="commonRules saperationSection">
                        <Box className="sectionTitle">{x.title}</Box>
                        <Typography>
                          <span className="bulletPoint"></span>Once you played,
                          lottery ticket numbers will not be editable and
                          cancelable.
                        </Typography>
                        <Typography>
                          <span className="bulletPoint"></span>The Lottery Game
                          Play will be stopped at {x.gameStopHour} CST on every
                          friday.
                        </Typography>
                        <Typography>
                          <span className="bulletPoint"></span>The lottery game
                          discounts and winnings as follows.
                        </Typography>
                      </Box>

                      <Box className="commonRuleTable saperationSection">
                        <TableContainer component={Paper}>
                          <Table aria-label="spanning table">
                            <TableHead className="commonBgCard">
                              <TableRow>
                                <TableCell rowSpan={2} className="textCenter">
                                  Game Type
                                </TableCell>
                                <TableCell colSpan={2} className="textCenter">
                                  Winning Amount
                                </TableCell>
                                <TableCell rowSpan={2} className="textCenter">
                                  Play Discount
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="textCenter">
                                  Straight
                                </TableCell>
                                <TableCell className="textCenter">
                                  Rumble
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {x.gameDataWinningsDiscounts &&
                                x.gameDataWinningsDiscounts.map(
                                  (y: GameDataWinningsDiscount) => (
                                    <TableRow>
                                      <TableCell className="textCenter">
                                        {y.gameType}
                                      </TableCell>
                                      <TableCell className="textCenter">
                                        {y.straight}
                                      </TableCell>
                                      <TableCell className="textCenter">
                                        {y.rumble}
                                      </TableCell>
                                      <TableCell className="textCenter">
                                        {y.playDiscount}%
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Container>

          <Box className="referandearnSection saperationSection">
            <Container>
              <Box className="commonBgCard">
                <Box>
                  <Typography className="referEarnTitle">
                    If You are interested <br />
                    in Thai lottery Online
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  className="primaryFillBtn referEarnBtn"
                >
                  <span>Refer a Friend and Earn Money</span>
                </Button>
              </Box>
            </Container>
          </Box>

          <Box className="acceptedPayments saperationSection">
            <Typography className="landingPageHeading">
              Accepted Payment Methods
            </Typography>
            <Marquee>
              <List>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_UPI.png")}
                        alt="Payment UPI"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment UPI"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_Bank.png")}
                        alt="Payment Bank"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment Bank"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_StcPay.png")}
                        alt="Payment STC PAY"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment STC Pay"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_NCB.png")}
                        alt="Payment NCB"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment NCB"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_AlRajhiBank.png")}
                        alt="Payment AlRajhiBank"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment AlRajhiBank"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_WesternUnion.png")}
                        alt="Payment WesternUnion"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment WesternUnion"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_EVoucher.png")}
                        alt="Payment EVoucher"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment EVoucher"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_MasterCard.png")}
                        alt="Payment MasterCard"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment MasterCard"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_Visa.png")}
                        alt="Payment Visa"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment Visa"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_PayPal.png")}
                        alt="Payment PayPal"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment PayPal"
                  />
                </ListItem>
                <ListItem className="commonListItem">
                  <ListItemAvatar>
                    <Avatar className="listAvtar">
                      <img
                        className="width100"
                        src={require("../../assets/images/home/Payment_Crypto.png")}
                        alt="Payment Crypto"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="listAvtarTitle"
                    primary="Payment Crypto"
                  />
                </ListItem>
              </List>
            </Marquee>
          </Box>

          <Box className="playStoreSection saperationSection"></Box>

          <Box className="landingPageFooter">
            <MainFooter />
          </Box>
          <Box className="pageViewFooter">
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Rules;

import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import ImageList from "@mui/material/ImageList";
import Box from "@mui/material/Box";

//List Import
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

//Icons
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BoltIcon from "@mui/icons-material/Bolt";
import PaymentsIcon from "@mui/icons-material/Payments";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GamesIcon from "@mui/icons-material/Games";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import CycloneRoundedIcon from "@mui/icons-material/CycloneRounded";
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
import GamepadRoundedIcon from "@mui/icons-material/GamepadRounded";
import BatteryChargingFullRoundedIcon from "@mui/icons-material/BatteryChargingFullRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import LanguageIcon from "@mui/icons-material/Language";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded";
import { useDispatch, useSelector } from "react-redux";
import _menuMap from "../../assets/staticDataMapping/roleMenuMapping.json";
import { IMenuItem } from "../../interface/IMenuItem";
import { RoleType } from "../../constants/DBConstants.contant";
import { PreferencesContext } from "../../context/preferences.context";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { logOut, sessionExpired } from "../../actions/auth.actions";
import "../leftMenu//leftMenu.component.style.css";

const LeftMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.authReducer);
  const [menuList, setMenuList] = useState<IMenuItem[]>([]);
  const { setActiveMenuItem, activeMenuItem } = useContext(PreferencesContext);
  const logoutUser = () => dispatch<any>(logOut());

  useEffect(() => {
    if (userInfo && userInfo.user && userInfo.user.userId != "") {
      const roleId: number = userInfo.user.roleId;
      console.log(_menuMap);
      switch (roleId) {
        case RoleType.SuperAdmin:
          setMenuList(_menuMap[RoleType.SuperAdmin - 1] as IMenuItem[]); // Index 0
          break;
        case RoleType.Admin:
          setMenuList(_menuMap[RoleType.Admin - 1] as IMenuItem[]); // Index 1
          break;
        case RoleType.User:
          setMenuList(_menuMap[RoleType.User - 1] as IMenuItem[]); // Index 2
          break;
        case RoleType.Agent:
          setMenuList(_menuMap[3] as IMenuItem[]); // Index 2
          break;
      }
    }
  }, []);

  const RenderIcon = (props: any) => {
    switch (props.menuItem.toLowerCase()) {
      case "dashboard":
        return <GridViewRoundedIcon />;
      case "exchangerates":
        return <CompareArrowsRoundedIcon />;
      case "mywallet":
        return <AccountBalanceWalletIcon />;
      case "mywalletrecharge":
        return <BoltIcon />;
      case "mywalletwithdraw":
        return <PaymentsIcon />;
      case "mybankcards":
        return <CreditCardIcon />;
      case "playgames":
        return <GamesIcon />;
      case "lotteryplayhistory":
        return <HistoryRoundedIcon />;
      case "transactionsummary":
        return <ReceiptRoundedIcon />;
      case "profilesettings":
        return <ManageAccountsRoundedIcon />;
      case "offers":
        return <LocalOfferRoundedIcon />;
      case "referrallist":
        return <GroupAddRoundedIcon />;
      case "help":
        return <HelpRoundedIcon />;
      case "rechargeuser":
        return <RecentActorsRoundedIcon />;
      case "allusers":
        return <FolderSharedRoundedIcon />;
      case "gamesettings":
        return <GamepadRoundedIcon />;
      case "lotterygamesettings":
        return <CycloneRoundedIcon />;
      case "paymenttransactions":
        return <PaymentsRoundedIcon />;
      case "agentrechargelist":
        return <BatteryChargingFullRoundedIcon />;
      case "LotteryGameSummary":
        return <InsertDriveFileRoundedIcon />;
      case "changepassword":
        return <VpnKeyOutlinedIcon />;
      case "managecountry":
        return <LanguageIcon />;
      case "declareresults":
        return <AssignmentTurnedInRoundedIcon />;
      case "lotterygamesummary":
        return <StickyNote2RoundedIcon />;
      case "applicationagents":
        return <SupportAgentRoundedIcon />;
      case "logout":
        return <ExitToAppOutlinedIcon />;
      default:
        return <HelpRoundedIcon />;
    }
  };

  const _handleRedirection = (menuItem: string) => {
    switch (menuItem) {
      case "logout":
        logoutUser();
        break;
      default:
        setActiveMenuItem(menuItem);
        navigate(`/${menuItem}`);
        break;
    }
  };

  const [isToggled, setIsToggled] = useState(false);

  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  const getActiveClass = (navigateTo: string) => {
    let classes = "leftNavItem";
    if (activeMenuItem === navigateTo) {
      classes += " activeMenuItem";
    }

    return classes;
  };

  return (
    <Box
      className={`height100 leftMenuSection ${
        isToggled ? "toggled-class" : ""
      }`}
    >
      <Box className="brandPart">
        <img
          alt="BetBhat"
          className="portalLogo"
          src={require("../../assets/images/common/logo.png")}
        />
        <img
          alt="BetBhat"
          className="portalLogoIcon"
          src={require("../../assets/images/common/icon.png")}
        />
        <button onClick={toggleClass} className="leftMenuAction">
          <MenuOpenRoundedIcon />
        </button>
      </Box>
      <Box className="brandNavigation">
        <nav>
          <List>
            {menuList &&
              menuList.map((menuItem: IMenuItem, i: number) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    component="a"
                    className={getActiveClass(menuItem.navigateTo)}
                    onClick={() => _handleRedirection(menuItem.navigateTo)}
                  >
                    <ListItemIcon className="brandNavigationIcon">
                      <RenderIcon menuItem={menuItem.navigateTo} />
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.title}
                      className="brandNavigationTitle"
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </nav>
      </Box>
    </Box>
  );
};

export default LeftMenu;
